<script>

export default {
    data() {
        return {
            displayUpdate: {}
        }
    },
    props: {
        keyresult: Object
    },
    methods: {
        getProgressClasses: function(k) {
            const classes = [];
            if (k?.confidence) {
                classes.push(classDict[k.confidence]);
            }
            else {
                classes.push("is-primary");
            }

            return classes;
        }
    }
}
</script>

<template>
    <div style="width: 80%; margin: 8px; vertical-align: middle;">
        <div style="display: inline-block; width: 2%"></div>
        <div style="display: inline-block; width: 15%; padding-right: 1em;"><progress style="width: 100%" class="nes-progress is-primary" :value="keyresult.progress" max="1"></progress></div>
        <div style="display: inline-block; text-align: center; padding-right: 1em;"><a :href="keyresult.url" target="_blank" rel="noopener noreferrer">Link</a></div>
        <div style="display: inline-block; width: 75%; padding-left: 1em; position: relative;"><img style="position:absolute; left: -0.7em; bottom: 0px" v-if="keyresult.progress === 1" src="./../assets/check-1.png" alt="Completed" /> {{ keyresult.title }}</div>
    </div>
</template>