import { createApp } from 'vue'
import App from './App.vue'
import Login from './Login.vue'
import * as VueRouter from 'vue-router'
import auth, {authService} from './auth'
import { credentials } from './stores/credentialsStore';
import './assets/main.css'

const routes = [
    { name: 'Home', path: '/', component: App },
    { path: '/login', component: Login },
    { path: '/logout', component: App }
]

const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes,
});

router.beforeEach(async (to, from) => {
    if (to.path === '/login') {
      try {
        var user = await authService.handleLoginRedirect()
      }
      catch (e) {
        console.error(e);
      }
        
      window.location.replace("/");
    }
    else if (to.path === '/logout') {
      var user = await authService.getUser();
      if (user !== null) {
        await authService.logout(user.id_token);
      }
      window.location.replace("/");
    }
});

const app = createApp(App);
app.use(auth);
app.use(router);
app.mount('#app')
