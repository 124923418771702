<script setup>
import Objective from './components/Objective.vue';
</script>

<script>
import { credentials } from './stores/credentialsStore.js';

export default {
  data() {
    return {
      json: {},
      user: null,
      isLoggedIn: false,
      refreshTimer: undefined,
    }
  },
  watch: {
    user(newUser, oldUser) {
      if (newUser) {
        credentials.user = newUser;
        credentials.profile = newUser.profile;
      }
    }
  },
  methods: {
    refreshData: async function() {
      var result = await fetch('https://kl05w78wm6.execute-api.eu-west-1.amazonaws.com/okr-summary', {
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': this.user.access_token,
          'Content-Type': 'application/json'
        }
      });
      this.json = await result.json();

      this.clearRefresh();
      this.refreshTimer = setTimeout(this.refreshData.bind(this), this.nextRefresh());
    },
    nextRefresh: function() {
      return  (3600 - (Math.round((new Date()).getTime() / 1000) % 3600)) * 1000;
    },
    clearRefresh() {
      if(this.refreshTimer) {
        clearTimeout(this.refreshTimer);
        this.refreshTimer = undefined;
      }
    }
  },
  async mounted() {
    this.user = await this.$auth.getUser();
    this.isLoggedIn = this.user !== null && await this.$auth.isUserLoggedIn();

    if (this.isLoggedIn) {
      this.refreshData();
    }
  },
  unmounted() {
    this.clearRefresh();
  }
}
</script>

<template>
  <h1>Engineering OKRs</h1>
  <hr />
  <br />
  <div class="button-container">
    <button type="button" v-if="!isLoggedIn" @click="$auth.login()">Login</button>
    <button type="button" v-if="isLoggedIn" @click="$auth.logout()">Logout</button>
  </div>
  <br />
  <hr />
  <br />

  <main v-if="isLoggedIn">
    <template v-for="objective in json">
      <Objective :objective="objective" />
    </template>
  </main>
</template>

<style scoped>
</style>
