import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { credentials } from './stores/credentialsStore';
import settings from './oidcSettings.json'

let userManager = new UserManager({...settings, ...{userStore: new WebStorageStateStore({ store: window.sessionStorage })}});

class AuthService {
    async login() {
        return await userManager.signinRedirect()
    }

    async logout(id_token_hint) {
      return await userManager.signoutRedirect({id_token_hint: id_token_hint});
    }

    async handleLoginRedirect() {
      return await userManager.signinRedirectCallback();
    }

    async isUserLoggedIn () {
      var user = await userManager.getUser();
        return user !== null && !user.expired;
    }

    async getProfile () {
        return (await userManager.getUser()).profile
    }

    async getUser() {
        return await userManager.getUser();
    }

    getAccessToken () {
        return this.getUser()?.getAccessToken();
    }
}

export const authService = new AuthService();
export default {
    install: function (app) {
        app.config.globalProperties.$auth = authService
    }
}