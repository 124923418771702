<script setup>
import KeyResults from './KeyResults.vue';
</script>

<script>

export default {
    props: {
        objective: Object,
        displayConfidenceLevels: Array
    },
    computed: {
        sorted_kr() {
            return this.objective.key_results.toSorted((a, b) => a.title.localeCompare(b.title));
        }
    }
}
</script>

<template>
    <div class="window" style="width: 100%">
        <div class="title-bar">
            <div class="title-bar-text">{{ objective.title }}</div>
            <div class="title-bar-controls">
                <button aria-label="Close"></button>
            </div>
        </div>
        <div class="window-body" style="text-align: left;">
            <p><progress style="width: 100%" class="nes-progress is-primary" :value="objective.progress" max="1"></progress></p>
            <template v-for="kr in sorted_kr">
                <KeyResults :keyresult="kr" />
            </template>
        </div>
        <div class="status-bar" style="text-align: right;">
            <p class="status-bar-field"><a :href="objective.url" target="_blank" rel="noopener noreferrer"><span class="nes-text is-primary">Link</span></a></p>
        </div>
    </div>
    <br />
</template>